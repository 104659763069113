import React from "react";
import {
    Nav,
    NavLogo,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink,
} from "./NavBarElement";

const Navbar = () => {
    return (
        <>
           <Nav>
            <NavLogo to="/">
                <img src="/images/header-logo.svg" width="185px" height="37px"/>
            </NavLogo>
            <NavBtn>
                <a className="launch-app-btn" href="https://app.beamswap.io" target="_blank" style={{fontSize: '16px', marginLeft: '14px'}}>Launch App</a>                
            </NavBtn>
           </Nav> 
        </>
    );
};
export default Navbar;