import React from "react";
import Footer from "../../components/footer";

const Home = () => {
    return (
        <div>
            <div className="landing-container">

                {/* Absolute positioned stuff */}
                <div className="section-1-background-glow"></div>
                <img className="section-1-background" src="/images/landing-bg.png" />
                <img className="section-1-moon" src="/images/landing-moon.svg" />

                <div className="section-1-wrapper">
                    <div className="section-1-title">
                        DEFI HUB ON
                    </div>
                    <div className="section-1-moonbeam">
                        <img src="/images/landing-moonbeam-logo.svg" />
                    </div>
                    <div className="section-1-subtitle">
                        Swap, earn, launch your projects and more. All in one place and simple to use.
                    </div>
                    <div className="section-1-buttons">
                        <a href="https://app.beamswap.io" target="_blank" style={{ textDecoration: 'none' }}>
                            <div className="connect">Launch app</div>
                        </a>
                        <a href="https://docs.beamswap.io" target="_blank" style={{ textDecoration: 'none', color: '#fff  ' }}>
                            <div className="trade">Docs</div>
                        </a>
                    </div>
                  
                </div>
            </div>

            <div className="landing-container section-1-2">
                <div className="title">
                    Core Features
                </div>
                <div className="section-3-lower">
                    <a href="https://app.beamswap.io/exchange/swap" target={"_blank"} style={{textDecoration:"none",color:"inherit"}}>  <div className="card">
                        <div className="image">
                            <img src="/images/landing-core-swap.svg" width="120px" height="120px" />
                            <img className="hover" src="/images/landing-core-swap-white.svg" width="120px" height="120px" />
                        </div>
                        <div className="title">Swap Tokens</div>
                    </div></a>
                    <a href="https://app.beamswap.io/exchange/pool" target={"_blank"} style={{textDecoration:"none",color:"inherit"}}>     <div className="card">
                        <div className="image">
                            <img src="/images/landing-core-supply.svg" width="120px" height="120px" />
                            <img className="hover" src="/images/landing-core-supply-white.svg" width="120px" height="120px" />
                        </div>
                        <div className="title">Supply Liquidity</div>
                    </div> </a>
                    <a href="https://app.beamswap.io/farm" target={"_blank"} style={{textDecoration:"none",color:"inherit"}}>   <div className="card">
                        <div className="image">
                            <img src="/images/landing-core-farms.svg" width="120px" height="120px" />
                            <img className="hover" src="/images/landing-core-farms-white.svg" width="120px" height="120px" />
                        </div>
                        <div className="title">Yield Farms</div>
                    </div> </a>                        
                </div>               
            </div>            
           
            <div className="landing-container section-1">
                <div className="title">Some Trusted Partners</div>
                <div className="partners">
                    <div className="row">
                        <a href="https://multichain.org/" target={"_blank"} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="partner">
                                <img src="/images/multichain.png" width="200" height="auto" />
                            </div>
                        </a>
                        <a href="https://solidity.finance/" target={"_blank"} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="partner solidity">
                                <img src="/images/solidity-finance.png" width="100" height="auto" />
                            </div>
                        </a>
                        <a href="https://blastapi.io/" target={"_blank"} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="partner">
                                <img src="/images/blast.png" width="200" height="auto" />
                            </div>
                        </a>
                        <a href="https://bird.money/" target={"_blank"} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="partner">
                                <img src="/images/bird.png" width="130" height="auto" />
                            </div>
                        </a>
                        <a href="https://ola.finance/" target={"_blank"} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="partner">
                                <img src="/images/landing-partners-ola.png" width="130" height="auto" />
                            </div>
                        </a>
                        <a href="https://www.diadata.org/" target={"_blank"} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="partner">
                                <img src="/images/landing-partners-dia.svg" width="130" height="auto" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            {/* 
            <div className="landing-container section-2">
                <div className="section-2-title">
                    Lorem Ipsum is simply dummy.
                    Lorem Ipsum is simply.
                </div>
                <div className="section-2-subtitle">
                    Lorem ipsum is simply dummy text of the printing and typesettings industry.
                    Lorem ipsum has been the industry's standard dummy
                </div>

                <div className="section-2-metrics">
                    <div className="metric">
                        <div className="title">TOTAL VOLUME</div>
                        <div className="value">$85.86b+</div>
                    </div>
                    <div className="metric">
                        <div className="title">TOTAL TRADES</div>
                        <div className="value">6.5M+</div>
                    </div>
                    <div className="metric">
                        <div className="title">SOURCES CONNECTED</div>
                        <div className="value">129</div>
                    </div>
                    <div className="metric">
                        <div className="title">SUPPORTED NETWORKS</div>
                        <div className="value">12</div>
                    </div>
                    <div className="metric">
                        <div className="title">TOTAL USERS</div>
                        <div className="value">820k+</div>
                    </div>
                    <div className="metric">
                        <div className="title">ACCESS LIQUIDITY</div>
                        <div className="value">$43b+</div>
                    </div>
                    <div className="metric">
                        <div className="title">AGGREGATION SHARE</div>
                        <div className="value">58.90%</div>
                    </div>
                    <div className="metric">
                        <div className="title">COMMUNITY DELEGATES</div>
                        <div className="value">8.400+</div>
                    </div>
                </div>
            </div>
            */}


            {/* <div className="landing-container section-3">
                <div className="section-3-upper">
                    <div className="description">
                        <div className="title">
                            Lorem Ipsum is simply dummy Lorem
                        </div>
                        <div className="subtitle">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                        </div>
                    </div>
                    <div className="buttons">
                        <div className="connect">Launch App</div>
                        <div className="trade">Trade now</div>
                    </div>
                </div>
                <div className="section-3-lower">
                    <div className="card">
                        <div className="image"><img src="/images/landing-card.svg" width="120px" height="120px" /></div>
                        <div className="title">Lorem Ipsum</div>
                        <div className="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</div>
                    </div>
                    <div className="card">
                        <div className="image"><img src="/images/landing-card.svg" width="120px" height="120px" /></div>
                        <div className="title">Lorem Ipsum</div>
                        <div className="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</div>
                    </div>
                    <div className="card">
                        <div className="image"><img src="/images/landing-card.svg" width="120px" height="120px" /></div>
                        <div className="title">Lorem Ipsum</div>
                        <div className="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</div>
                    </div>
                </div>
            </div> */}

            <div className="landing-container section-4">

                <div className="subsection">
                    <div className="description">
                        <div className="title">
                            Integrated Bridge
                        </div>
                        <div className="subtitle">
                            Bridge your cryptocurrencies from other EVM chains to Moonbeam and vise versa with Beamswap's bridge
                        </div>
                        <a href="https://app.beamswap.io/bridge" target="_blank" style={{ textDecoration: 'none', color: '#fff' }}>
                            <div className="button">
                                Bridge
                            </div>
                        </a>

                    </div>
                    <div className="image">
                        <img src="/images/landing-charts-1.svg" width="670px" height="670px" />
                    </div>
                </div>

                <div className="subsection left">
                    <div className="image">
                        <img className="left" src="/images/landing-charts-2.svg" width="670px" height="670px" />
                    </div>
                    <div className="description left">
                        <div className="title">
                            Beamshare token
                        </div>
                        <div className="subtitle">
                            When you stake your $GLINT in the single sided auto-compounding buyback pool you will receive Beamshare tokens as receipt, representing your share of the pool.
                        </div>
                        <a href="https://app.beamswap.io/beamshare" target="_blank" style={{ textDecoration: 'none', color: '#fff' }}>
                            <div className="button">
                                Beamshare
                            </div>
                        </a>
                    </div>
                </div>

                <div className="subsection">
                    <div className="description">
                        <div className="title">
                            Variety of features
                        </div>
                        <div className="subtitle">
                            Limit orders, portfolio tracker, charts and more! Take advantage of the features that Beamswap has to offer!
                        </div>
                        <a href="https://app.beamswap.io" target="_blank" style={{ textDecoration: 'none', color: '#fff' }}>
                            <div className="button">
                                Beamswap
                            </div>
                        </a>
                    </div>
                    <div className="image">
                        <img src="/images/landing-charts-3.svg" width="670px" height="670px" />
                    </div>
                </div>

            </div>

            <div className="landing-container section-6">
                <div className="wrapper">
                    <div className="left">
                        <div className="title">
                            Beamswap is Powered by <span style={{ color: 'aqua', fontWeight: 'bold' }}>$GLINT</span>
                        </div>
                        <div className="info">
                            <div className="image">
                                <img src="/images/landing-6-stake.svg" width="84px" height="84px" />
                            </div>
                            <div className="description">
                                Stake GLINT to receive a portion of the DEX Fees
                            </div>
                        </div>
                        <div className="info">
                            <div className="image">
                                <img src="/images/landing-6-use.svg" width="84px" height="84px" />
                            </div>
                            <div className="description">
                                Use GLINT for Governance! Participate in the important decisions
                            </div>
                        </div>
                        <div className="info">
                            <div className="image">
                                <img src="/images/landing-6-participate.svg" width="84px" height="84px" />
                            </div>
                            <div className="description">
                                Participate in our Launchpad projects and increase your allocation with GLINT
                            </div>
                        </div>
                        <a href="https://beamswap.io/tokenomics.pdf" target="_blank" style={{ textDecoration: 'none', color: '#fff' }}>
                            <div className="button">
                                Glint Tokenomics
                            </div>
                        </a>
                    </div>
                    <div className="right">
                        <div className="image">
                            <img src="/images/landing-6-right.svg" height="450px" width="450px" />
                        </div>
                    </div>
                </div>
                {/* <img src="/images/landing-section-5-background.png" className="background"></img>                 */}
            </div>


            {/* <div className="landing-container section-5">
                <div className="banner">
                    <div className="title">
                        Lorem Ipsum is The Best
                    </div>
                    <div className="subtitle">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </div>
                    <div className="subsubtitle">
                        Lorem Ipsum is simply dummy.
                    </div>

                    <div className="button">
                        Launch App
                    </div>

                </div>
            </div>  */}

            <div className="landing-container bg-deepcove buy-glint" style={{marginTop: -120, justifyContent: 'space-between', alignItems: 'center'}}>
                <div className="buy-title" style={{fontSize: 48, fontWeight: 'bold', position: 'relative'}}>
                    Buy $GLINT here
                    <img className="landing-tokens" src="/images/landing-tokens.svg" style={{position: 'absolute'}} />
                </div>
                <div id="checkout"></div>                 
            </div>


            <Footer />


        </div>
    );
};

export default Home;