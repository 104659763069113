import React from "react";

const Footer = () => {
    return (
        <>
           <div className="footer">
               <div className="groups">
                    <div className="group image">
                        <div className="logo"><img src="/images/header-logo.svg" /></div>
                        <div className="description">Decentralized exchange (DEX) with an automated market maker (AMM), providing liquidity and peer-to-peer transactions, built on the Moonbeam network.</div>
                    </div>
                    <div className="group">
                        <div className="title">Product</div>
                        <div className="links">
                            <a href="https://app.beamswap.io/exchange/swap">Swap</a>
                            <a href="https://app.beamswap.io/exchange/pool">Liquidity</a>
                            <a href="https://app.beamswap.io/farm">Yield Farms</a>
                            <a href="https://app.beamswap.io/beamshare">Beamshare</a>
                            <a href="https://app.beamswap.io/launchpad">Launchpad</a>
                            {/* <a href="/">Analytics</a> */}
                            <a href="https://app.beamswap.io/bridge">Bridge</a>
                            <a href="https://app.beamswap.io/locker">Locker</a>
                        </div>
                    </div>
                    <div className="group">
                        <div className="title">Support</div>
                        <div className="links">
                            <a href="https://docs.beamswap.io">Documentation</a>
                            <a href="/terms">Terms and Conditions </a>
                            <a href="/privacy">Privacy Policy </a>
                            {/* <a href="/">How to list?</a>
                            <a href="/">Apply for a Farm</a>
                            <a href="/">Careers</a> */}
                        </div>
                    </div>
                    <div className="group social">
                        <div className="title">Get in touch</div>
                        <div className="socials">
                            <a href="https://twitter.com/Beamswapio" target="_blank">
                                <img src="/images/footer-twitter.svg" width="36px" height="36px" />
                                <img className="hover" src="/images/footer-twitter-hover.svg" width="36px" height="36px" />
                            </a>
                            <a href="https://github.com/BeamSwap" target="_blank">
                                <img src="/images/footer-github.svg" width="36px" height="36px" />
                                <img className="hover" src="/images/footer-github-hover.svg" width="36px" height="36px" />
                            </a>
                            <a href="https://t.me/Beamswap" target="_blank">
                                <img src="/images/footer-telegram.svg" width="36px" height="36px" />
                                <img className="hover" src="/images/footer-telegram-hover.svg" width="36px" height="36px" />
                            </a>
                            <a href="https://discord.gg/XRpPFHqJ" target="_blank">
                                <img src="/images/footer-facebook.svg" width="36px" height="36px" />
                                <img className="hover" src="/images/footer-facebook-hover.svg" width="36px" height="36px" />
                            </a>
                        </div>
                    </div>
               </div>               
           </div>           
        </>
    );
};
export default Footer;