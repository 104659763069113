import '../src/styles/global.scss'
import Navbar from "./components/navbar";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Switch ,Route } from "react-router-dom";
import Home from './pages/home';
import Kyc from './pages/kyc';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import TermsOfUse from "./components/termsofuse"
console.log = () => {}
console.warn = () => {}
console.error = () => {}
function App() {
  ReactGA.initialize([
    {
      trackingId: "G-Q5ZHJ2FZG0",
    },
  ]);
  return (
    <Router>
      <img className="section-1-glow" src="/images/landing-glow.png" />                        
      <img className="section-1-partners-overlay" src="/images/landing-partners-overlay.svg" />         
      <img className="section-1-partners-overlay-left" src="/images/landing-partners-overlay.svg" />                     
      <Navbar />
      <TermsOfUse />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/kyc" component={Kyc} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
      </Switch>
    </Router>
  );
}

export default App;
