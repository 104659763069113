import React from "react";
import Footer from "../../components/footer";

const Home = () => {
    window.location.replace("https://fractal.id/authorize?client_id=q8ThQnupxjJHSJs9sUPn_45YUcbpk_oM2mAd83ToskU&redirect_uri=https%3A%2F%2Fapp.beamswap.io&response_type=code&scope=contact%3Aread%20verification.basic%3Aread%20verification.basic.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.wallet%3Aread%20verification.wallet.details%3Aread&theme=dark");
    return (
        <></>
    );
};

export default Home;